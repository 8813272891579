<template>
  <div id="home">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.dashboard') }}</h2>

          <p></p>
        </div>

        <div class="calendar_content">
          <Rebate ref="rebate" :userID="userID" @accountIdAndCurrency="getAccountIdAndCurrency"></Rebate>

          <div class="bottom">
            <dateRangePicker v-bind:start-date.sync="startTime" v-bind:end-date.sync="endTime"></dateRangePicker>

            <div class="form_button">
              <el-button class="purple_button" @click="setStartAndEndDate" data-testid="UPDATE">
                {{ $t('home.UPDATE') }}
              </el-button>
            </div>
          </div>

          <div class="calendar_content_bottom">
            <ul class="clearfix">
              <li
                @click="currLineChartStatus = 'REBATE'"
                :class="{ active: currLineChartStatus === 'REBATE' }"
                data-testid="REBATE"
              >
                <div class="left">
                  <img
                    @click="goToRebateReport"
                    src="@/assets/uploads/icons-big-meta-trader@3x.png"
                    class="calendar_more"
                    data-testid="goToRebateReport"
                  />
                </div>

                <div class="right">
                  <div>
                    <p v-textOverflow v-if="ibDataPermission && hasTransferPermission">
                      {{ currAccountCurrency | currencySymbol }} {{ accountDatas.totalCommission | currency('') }}
                    </p>
                    <p v-textOverflow v-else>***</p>
                    <span>{{ $t('home.REBATE') }}</span>
                  </div>
                </div>
              </li>

              <li
                @click="currLineChartStatus = 'TOTAL_VOLUME'"
                :class="{ active: currLineChartStatus === 'TOTAL_VOLUME' }"
                data-testid="TOTAL_VOLUME"
              >
                <div class="left">
                  <img
                    @click="goToRebateReport"
                    src="@/assets/uploads/icons-big-data@3x.png"
                    class="calendar_more"
                    data-testid="goToRebateReport2"
                  />
                </div>
                <div class="right">
                  <div>
                    <p v-textOverflow v-if="ibDataPermission">{{ accountDatas.totalVolume | currency('') }}</p>
                    <p v-textOverflow v-else>***</p>

                    <span>{{ $t('home.TOTAL_VOLUME') }}</span>
                  </div>
                </div>
              </li>

              <li
                @click="currLineChartStatus = 'NET_FUNDING'"
                :class="{ active: currLineChartStatus === 'NET_FUNDING' }"
                data-testid="NET_FUNDING"
              >
                <div class="left">
                  <img
                    @click="goToIbReport"
                    src="@/assets/uploads/icons-big-trade-markets@3x.png"
                    class="calendar_more"
                    data-testid="goToIbReport"
                  />
                </div>
                <div class="right">
                  <div>
                    <p v-textOverflow v-if="ibDataPermission">
                      {{ currAccountCurrency | currencySymbol }} {{ accountDatas.netDeposit | currency('') }}
                    </p>
                    <p v-textOverflow v-else>***</p>

                    <span>{{ $t('home.NET_FUNDING') }}</span>
                  </div>
                </div>
              </li>

              <li
                @click="currLineChartStatus = 'DEPOSITS'"
                :class="{ active: currLineChartStatus === 'DEPOSITS' }"
                data-testid="DEPOSITS"
              >
                <div class="left">
                  <img
                    @click="goToIbReport"
                    src="@/assets/uploads/icons-big-funding@3x.png"
                    class="calendar_more"
                    data-testid="goToIbReport2"
                  />
                </div>

                <div class="right">
                  <div>
                    <p v-textOverflow v-if="ibDataPermission">
                      {{ currAccountCurrency | currencySymbol }} {{ accountDatas.totalDeposit | currency('') }}
                    </p>
                    <p v-textOverflow v-else>***</p>

                    <span>{{ $t('home.DEPOSITS') }}</span>

                    <img src="@/assets/uploads/icons-stock-up@3x.png" alt />
                  </div>
                </div>
              </li>

              <li
                @click="currLineChartStatus = 'WITHDRAW'"
                :class="{ active: currLineChartStatus === 'WITHDRAW' }"
                data-testid="WITHDRAW"
              >
                <div class="left">
                  <img
                    @click="goToIbReport"
                    src="@/assets/uploads/icons-big-low-cost@3x.png"
                    class="calendar_more"
                    data-testid="goToIbReport3"
                  />
                </div>
                <div class="right">
                  <div>
                    <p v-textOverflow v-if="ibDataPermission">
                      {{ currAccountCurrency | currencySymbol }} {{ accountDatas.totalWithdrawal | currency('') }}
                    </p>
                    <p v-textOverflow v-else>***</p>

                    <span>{{ $t('home.WITHDRAW') }}</span>

                    <img src="@/assets/uploads/icons-stock-down@3x.png" alt />
                  </div>
                </div>
              </li>

              <li
                @click="currLineChartStatus = 'OPEND_ACCOUNT'"
                :class="{ active: currLineChartStatus === 'OPEND_ACCOUNT' }"
                data-testid="OPEND_ACCOUNT"
              >
                <div class="left">
                  <img
                    @click="goToIbReport"
                    src="@/assets/uploads/icons-big-client-portal@2x.png"
                    class="calendar_more"
                    data-testid="goToIbReport4"
                  />
                </div>
                <div class="right">
                  <div>
                    <p v-textOverflow v-if="ibDataPermission">{{ accountDatas.newOpenUserTotal }}</p>
                    <p v-textOverflow v-else>***</p>

                    <span>{{ $t('home.OPEND_ACCOUNT') }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Chart ref="chart" :accountID="accountID" :startTime="startTime" :endTime="endTime" :userID="userID"></Chart>

        <div class="tables clearfix">
          <div class="tables_top">
            <div class="table_list">
              <div class="table_list_top">
                <span>{{ $t('home.TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH') }}</span>
              </div>

              <div class="table_list_content">
                <el-table :data="top6" height="100%" style="width: 100%">
                  <el-table-column prop="agentLowAccount" :label="$t('home.Account') + ' No'"></el-table-column>

                  <el-table-column prop="name" :label="$t('home.Name')">
                    <template slot-scope="scope">
                      <span v-textOverflow>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column prop="volume" :label="$t('home.Volume')">
                    <template slot-scope="topPerformScope">{{ topPerformScope.row.volume | currency('') }}</template>
                  </el-table-column>

                  <el-table-column prop="commission" :label="$t('home.Rebate')">
                    <template slot-scope="topPerformScope">
                      <span v-if="topPerformScope.row.commission == '***'"> *** </span>
                      <span v-else>{{ topPerformScope.row.commission | currency(' ') }} </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div class="table_list">
              <div class="table_list_top">
                <span>{{ $t('home.RECENTLY_OPENED_ACCOUNTS') }}</span>
              </div>

              <div class="table_list_content">
                <el-table :data="nearestOpenAccount" height="100%" style="width: 100%">
                  <el-table-column prop="tradeAccount" :label="$t('home.Account') + ' No'"></el-table-column>

                  <el-table-column prop="userName" :label="$t('home.Name')">
                    <template slot-scope="scope">
                      <span v-textOverflow>{{ scope.row.userName }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column prop="balance" :label="$t('home.Balance')">
                    <template slot-scope="scope">
                      <!-- -0.00000009表示后台未查到数据，则用 - 显示 -->

                      <span v-if="scope.row.balance == -0.00000009">-</span>

                      <span v-else>{{ scope.row.balance | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from './chart';
import Rebate from '@/components/Rebate';
import DateRangePicker from '@/components/DateRangePicker';

import {
  apiGetNearestOpenAccount,
  apiQueryRebateVolumeList,
  apiQueryTotalCommission,
  apiGetNewOpenUserTotal
} from '@/resource';
export default {
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startTime: '',
      endTime: '',
      top6: [],
      nearestOpenAccount: [],
      accountID: '',
      currAccountCurrency: '',
      accountDatas: {
        // 总手数
        totalVolume: 0,
        // 总入金
        totalDeposit: 0,
        // 净入金
        netDeposit: 0,
        // 总出金
        totalWithdrawal: 0,
        // 总佣金
        totalCommission: 0,
        // 可用余额
        availableBalance: 0,
        // 新开账号数
        newOpenUserTotal: 0
      },
      // 当前显示的什么标签的趋势图
      currLineChartStatus: 'REBATE'
    };
  },
  methods: {
    initData() {
      if (this.accountID) {
        Promise.all([
          this.getNearestOpenAccount(),
          this.queryRebateVolumeList(),
          this.queryTotalCommission(),
          this.getNewOpenUserTotal()
        ]);
      }
      // 每次请求数据，折线图恢复为默认字段
      this.currLineChartStatus = 'REBATE';
      // 更新折线图
      this.$nextTick(() => {
        this.$refs.chart.updateLineChart(this.currLineChartStatus);
        this.$refs.chart.updatePieChart();
      });
    },

    // 获取 最新6个开户账号
    getNearestOpenAccount() {
      apiGetNearestOpenAccount({
        mt4account: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.nearestOpenAccount = resp.data.data;
        } else if (resp.data.code === 305) {
          this.nearestOpenAccount = [];
        }
      }, this.$handleError);
    },
    // 获取佣金top6
    queryRebateVolumeList() {
      apiQueryRebateVolumeList({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.hasTransferPermission
            ? (this.top6 = resp.data.data)
            : (this.top6 = resp.data.data.map(item => {
                item.commission = '***';
                return item;
              }));
        } else if (resp.data.code === 305) {
          this.top6 = [];
        }
      }, this.$handleError);
    },
    // 获取 佣金 交易量 净入金 总入金 总出金
    queryTotalCommission() {
      apiQueryTotalCommission({
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startTime,
        qEndTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          Object.keys(respData).map(key => {
            this.$set(this.accountDatas, key, respData[key]);
          });
        } else if (resp.data.code === 305) {
          Object.keys(this.accountDatas).map(key => {
            this.$set(this.accountDatas, key, 0);
          });
        }
      }, this.$handleError);
    },
    // 获取 新开账号数
    getNewOpenUserTotal() {
      apiGetNewOpenUserTotal({
        mt4account: this.accountID,
        startTime: this.startTime,
        endTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) {
          this.$set(this.accountDatas, 'newOpenUserTotal', resp.data.data);
        } else if (resp.data.code === 305) {
          this.$set(this.accountDatas, 'newOpenUserTotal', 0);
        }
      }, this.$handleError);
    },
    setStartAndEndDate() {
      this.$nextTick(() => this.initData());
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      if (this.startTime && this.endTime) this.initData();
    },
    goToIbReport() {
      const cardMethod = this.currLineChartStatus == 'OPEND_ACCOUNT' ? `cardMethod=openedAccounts` : '';
      this.$router.push(`ibReport?${cardMethod}`);
    },
    goToRebateReport() {
      this.$router.push(`rebateReport`);
    }
  },
  watch: {
    currLineChartStatus(newStatus) {
      this.$nextTick(() => this.$refs.chart.updateLineChart(newStatus));
    }
  },
  components: {
    Chart,
    Rebate,
    DateRangePicker
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    ibDataPermission() {
      return this.$store.state.common.ibDataPermission;
    },
    hasTransferPermission() {
      return this.$store.state.common.operateAmountPermisison;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home/index.scss';
</style>
